import React, { useContext } from 'react';
import { ScrollView, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import _ from 'lodash';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';

import { getBrowserInfo } from './HeaderBar';
import Button from './Button';
import I18n, { formatDate, formatAmount } from '../i18n';
import { fetchUser } from '../api/Users';
import { fetchCalls } from '../api/Calls';
import { Coaching } from '../types';
import { fetchMandates } from '../api/Mandates';
import { fetchBillingDetailsList } from '../api/BillingDetails';
import { formatIban } from '../util/helpers';

interface InvoiceProps {
  coaching: Coaching,
}

const InvoiceModal = observer(({ coaching }: InvoiceProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const { browser, compactView } = getBrowserInfo();
  const { data: myDetails, error: errorMyDetails } = fetchUser(myId).swr;
  const { clientId } = coaching;
  const { data: clientDetails, error: errorClient } = fetchUser(clientId).swr;
  const { data: allCalls, error: errorCalls } = fetchCalls({ userId: clientId }).swr;
  const { data: mandates, error: errorMandate } = fetchMandates({ coachingId: coaching.id, $sort: '-created', $limit: 1 }).swr;
  const { data: billings, error: errorBilling } = fetchBillingDetailsList(myId).swr;

  if (errorMyDetails || errorClient || errorCalls || errorMandate || errorBilling) {
    return null;
  }
  const allUniqueCalls = _.uniqBy(allCalls, 'moduleId');
  const finishedCalls = _.filter(allCalls, { status: 'finished' });

  const mandate = mandates?.[0];
  const invoiceStatus = 'ready'; // get it from mandates.coaching[].invoiceStatus
  const billingDetails = Array.isArray(billings) ? billings[0] : undefined;
  const identifications = { creditNo: 'XXXXXX-XXXXXX', vatId: 'DE123456789', iban: billingDetails?.bankIban };
  const tableColWs = [250, 100, 100, 80, 150];
  const period = `${formatDate('date.monthYearShort', coaching.start)} - ${formatDate('date.monthYearShort', coaching.end)}`;

  const renderPayee = () => {
    if (!myDetails) return null;
    return (
      <View style={styles.infoSection}>
        <View style={styles.infoCol}>
          <Text style={styles.infoText}>{`${myDetails?.firstName} ${myDetails?.lastName || ''}`}</Text>
          <Text style={styles.infoText}>
            {`${billingDetails?.street || ''}\n${billingDetails?.zipCode || ''} ${billingDetails?.city || ''}`}
          </Text>
        </View>
        {invoiceStatus === 'ready' && (
          <View style={styles.infoCol}>
            <Button
              btnStyle={styles.changeButton}
              btnStyleHovered={styles.changeButtonH}
              onPress={() => { console.log('Redirect to change address page'); }}
              title={I18n.t('ui.buttons.changeAddress')}
              titleStyle={styles.changeButtonText}
              titleStyleHovered={styles.changeButtonTextH}
            />
          </View>
        )}
      </View>
    );
  };

  const renderIdsSection = () => {
    if (!myDetails) return null;
    return (
      <View style={styles.infoSection}>
        <View style={styles.infoCol}>
          <View style={styles.row}>
            <Text style={styles.idsTitle}>{I18n.t('ui.invoice.date')}</Text>
            <Text style={styles.infoText}>{formatDate('date.dayMonthYearShort', moment())}</Text>
          </View>

          {identifications.creditNo && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoice.creditNo')}</Text>
              <Text style={styles.infoText}>{identifications.creditNo}</Text>
            </View>
          )}

          {identifications.vatId && (
            <View style={styles.row}>
              <Text style={styles.idsTitle}>{I18n.t('ui.invoice.vatId')}</Text>
              <Text style={styles.infoText}>{identifications.vatId}</Text>
            </View>
          )}
        </View>

        {invoiceStatus === 'ready' && (
          <View style={styles.infoCol}>
            <Button
              btnStyle={styles.changeButton}
              btnStyleHovered={styles.changeButtonH}
              onPress={() => { console.log('Redirect to change address page'); }}
              title={I18n.t('ui.buttons.changeInfo')}
              titleStyle={styles.changeButtonText}
              titleStyleHovered={styles.changeButtonTextH}
            />
          </View>
        )}
      </View>
    );
  };

  const renderTransaction = () => {
    if (!myDetails) return null;
    const price = 1500.50;
    const number = 1;
    const totalNet = price;
    const taxPercentage = 19;
    const tax = totalNet * (taxPercentage / 100);
    const total = totalNet + Number(tax);
    const iban = formatIban(identifications?.iban || '');

    const addTableData = (
      col0: string | string[], col1: string,
      col2: string, col3: string, col4: string, title = false,
      rowStyle: ViewStyle = {},
    ) => {
      const cellStyle = (col: number, align: 'left' | 'right' = 'right') => (title ? [styles.tableTitle, { width: tableColWs[col], textAlign: align }, rowStyle]
        : [styles.bodyDes, { width: tableColWs[col], textAlign: align }, rowStyle]);

      return (
        <View style={styles.row}>
          {Array.isArray(col0) ? (
            <Text style={{ width: tableColWs[0] }}>
              <Text style={styles.bodyDes}>{col0[0]}</Text>
              <Text style={styles.coachingInfo}>{`\n${col0[1]}`}</Text>
            </Text>
          ) : <Text style={cellStyle(0, 'left')}>{col0}</Text>}
          <Text style={cellStyle(1)}>{col1}</Text>
          <Text style={cellStyle(2)}>{col2}</Text>
          <Text style={cellStyle(3)}>{col3}</Text>
          <Text style={cellStyle(4)}>{col4}</Text>
        </View>
      );
    };

    return (
      <View style={styles.transcation}>
        <Text style={styles.bodyTitle}>{I18n.t('ui.invoice.creditMemo')}</Text>
        <Text style={styles.bodyDes}>{I18n.t('ui.invoice.letter.salutation', { name: myDetails.firstName })}</Text>
        <Text style={styles.bodyDes}>{I18n.t('ui.invoice.letter.bodyIntro', { mandateRef: mandate?.id })}</Text>
        <View style={styles.table}>
          {addTableData(
            I18n.t('ui.invoice.letter.tableTitle.item'),
            I18n.t('ui.invoice.letter.tableTitle.period'),
            I18n.t('ui.invoice.letter.tableTitle.price'),
            I18n.t('ui.invoice.letter.tableTitle.number'),
            I18n.t('ui.invoice.letter.tableTitle.total'),
            true,
          )}
          <View style={styles.tableDivider} />
          {/* For multiple coaching make forloop here */}
          {addTableData(
            [
              I18n.t('ui.invoice.letter.tableValue.coaching'),
              I18n.t('ui.invoice.letter.tableValue.clientInfo',
                { code: clientDetails?.code || '', finishedCalls: finishedCalls.length, allCalls: allUniqueCalls.length }),
            ],
            period, formatAmount(price), number.toString(), formatAmount(price * number),
          )}
          <View style={styles.vSpace} />
          {addTableData(
            I18n.t('ui.invoice.letter.tableValue.totalNet'),
            '', '', '', formatAmount(totalNet), false, styles.bold,
          )}
          {addTableData(
            I18n.t('ui.invoice.letter.tableValue.tax', { val: taxPercentage }),
            '', '', '', formatAmount(tax), false, styles.tax,
          )}
          <View style={styles.tableDivider} />
          {addTableData(
            I18n.t('ui.invoice.letter.tableValue.total'),
            '', '', '', formatAmount(total), false, styles.bold,
          )}
        </View>
        <View style={styles.vSpace} />
        <Text style={styles.bodyDes}>
          {I18n.t('ui.invoice.letter.bodyDes')}
          <Text style={[styles.bodyDes, styles.bold]}>{`IBAN ${iban}.`}</Text>
        </Text>
        <View style={styles.vSpace} />
        <Text style={styles.bodyDes}>{I18n.t('ui.invoice.letter.bodyCon')}</Text>
      </View>
    );
  };

  const renderInvoice = () => (
    <ScrollView horizontal style={[{ alignSelf: 'center' }, compactView && { maxWidth: browser.width - 50 }]}>
      <View style={styles.invoice}>
        {renderPayee()}
        {renderIdsSection()}
        {renderTransaction()}
      </View>
    </ScrollView>
  );

  return (
    <View style={styles.container}>
      <View style={styles.status}>
        <Text style={styles.invoiceStatus}>{I18n.t(`ui.invoice.status.${invoiceStatus}`)}</Text>
      </View>
      {renderInvoice()}
      <View style={styles.status}>
        {invoiceStatus === 'ready' && (
          <View style={styles.buttonPad}>
            <Button
              btnStyle={styles.button}
              btnStyleHovered={styles.buttonHovered}
              onPress={() => { console.log('Send dispute req to api'); }}
              title={I18n.t('ui.buttons.dispute')}
            />
            <Button
              btnStyle={styles.button}
              btnStyleHovered={styles.buttonHovered}
              onPress={() => { console.log('Send payment req to api'); }}
              title={I18n.t('ui.buttons.reqPayment')}
            />
          </View>
        )}
      </View>
    </View>
  );
});

export default InvoiceModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    backgroundColor: Style.Color.White,
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  invoice: {
    backgroundColor: Style.Color.Gray100,
    alignSelf: 'center',
    paddingHorizontal: 30,
    paddingTop: 30,
    borderColor: Style.Color.Gray600,
    borderWidth: 1,
    marginBottom: 10,
  },
  infoSection: {
    marginBottom: 30,
    flexDirection: 'row',
  },
  transcation: {
    marginBottom: 30,
  },
  infoCol: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  infoText: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
  },
  status: {
    marginBottom: 30,
    width: '80%',
    alignSelf: 'center',
  },
  buttonPad: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 20,
    flexDirection: 'row',
  },
  changeButton: {
    backgroundColor: Style.Color.White,
    borderWidth: 1,
    borderColor: Style.Color.Gray400,
  },
  changeButtonText: {
    ...Style.Text.Normal,
    color: Style.Color.Gray400,
  },
  changeButtonH: {
    backgroundColor: Style.Color.Primary,
    borderColor: Style.Color.Primary,
  },
  changeButtonTextH: {
    color: Style.Color.White,
  },
  button: {
    marginLeft: 4,
  },
  buttonHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  invoiceStatus: {
    ...Style.Text.NormalBold,
    color: Style.Color.Black,
    width: '100%',
    textAlign: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  idsTitle: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
  },
  table: {
    flexDirection: 'column',
    width: '100%',
  },
  tableTitle: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
  },
  tableTitleRight: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Black,
    textAlign: 'right',
  },
  tableDivider: {
    width: 250 + 100 + 100 + 80 + 150 + 10,
    borderColor: Style.Color.Gray400,
    borderWidth: 1,
  },
  bodyTitle: {
    ...Style.Text.Heading1,
    color: Style.Color.Black,
    marginBottom: 20,
  },
  bodyDes: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 10,
  },
  bodyDesRight: {
    ...Style.Text.Normal,
    color: Style.Color.Black,
    marginBottom: 10,
    textAlign: 'right',
  },
  bold: {
    ...Style.Text.NormalBold,
  },
  vSpace: {
    height: 20,
  },
  coachingInfo: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
  },
  tax: {
    ...Style.Text.NormalItalic,
    color: Style.Color.Gray400,
  },
});
