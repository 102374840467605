import _ from 'lodash';

export const roles = {
  user: 'user',
  coach: 'coach',
  supervisor: 'supervisor',
  admin: 'admin',
  support: 'support',
  pm: 'pm',
  ops: 'ops',
  qa: 'qa',
  client: 'client',
};

export const allRoles = Object.values(roles);
export const screenAccess: { [key: string]: string[] } = {
  // Home: [roles.pm],
  Calls: [roles.coach, roles.supervisor, roles.support, roles.qa],
  Availabilities: [roles.coach, roles.supervisor, roles.support],
  Projects: [roles.pm, roles.support, roles.ops, roles.qa],
  Coachings: [roles.coach, roles.support, roles.qa],
  Profile: allRoles,
};

export const sectionAccess: { [key: string]: string[] } = {
  WeeklyAvailabilityPicker: [roles.supervisor],
};

export const sidebarSectionAccess: { [key: string]: string[] } = {
  Coaching: _.union(screenAccess.Calls, screenAccess.Availabilities),
  Resources: screenAccess.Projects,
  Settings: screenAccess.Profile,
  // Dashboards: screenAccess.Home,
};
