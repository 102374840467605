import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import _ from 'lodash';

import { RootStoreContext } from '../../stores/RootStore';
import Style from '../../style';
import I18n from '../../i18n';
import Section from '../Section';
import LoadingIndicator from '../LoadingIndicator';
import { Coaching } from '../../types';
import { isCloseToBottom } from '../../util/helpers';
import EmptyListHint from '../EmptyListHint';
import { fetchCoachings } from '../../api/Coachings';
import SearchBox from '../SearchBox';
import ModalView from '../ModalView';
import InvoiceModal from '../InvoiceModal';
import CoachingRow, { ButtonOptions, SelectedOptions } from './CoachingRow';

type CoachingStatus = 'active' | 'finished';

const LIMIT = 10;

interface CSProps {
  status: CoachingStatus;
}

const CoachingsSummary = ({ status }: CSProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const [page, setPage] = useState(0);
  const [selectedCoaching, setSelectedCoaching] = useState<SelectedOptions>();
  const [clientCode, setClientCode] = useState('');
  // Will be required when we will add invoice button here for compact view.
  const [selectedCoachingCell, setSelectedCoachingCell] = useState('');
  const allCoachings = useRef<Coaching[]>([]);
  const isOngoing = status === 'active';
  const query = {
    coachId: myId,
    $sort: '-created',
    $offset: page,
    $limit: LIMIT,
    $view: 'full',
    status: isOngoing ? 'active' : ['finished', 'aborted'],
  };

  const { data: allCoachingsForPage, error: errAllCoachings } = fetchCoachings({
    ...query,
    ...(clientCode !== '' && { clientCode }),
  }).swr;

  if (allCoachingsForPage && 'httpStatus' in allCoachingsForPage) {
    const isAuthFail = store.uiState.checkError(allCoachingsForPage);
    if (isAuthFail) return null;
  }

  if (allCoachingsForPage) allCoachings.current = _.unionBy(allCoachings.current, allCoachingsForPage, 'id');

  const scheduledCoachings = allCoachings.current;

  const sectionTitle = I18n.t(`ui.coachings.${status === 'active' ? 'ongoingCoachings' : 'finishedCoachings'}`);

  useEffect(() => {
    if ((!scheduledCoachings || scheduledCoachings.length < LIMIT)
      && allCoachingsForPage?.length === LIMIT && clientCode === '') {
      setPage(allCoachings.current.length);
    }
  }, [allCoachingsForPage]);

  const setSelectedCoachingFromChild = (coaching?: Coaching) => {
    setSelectedCoaching(coaching ? { coaching } : undefined);
    toggleActiveCell(coaching?.id || '', false);
  };

  const toggleActiveCell = (coachingId: string, isActiveCellCompact: boolean) => {
    if (!isActiveCellCompact) setSelectedCoachingCell(coachingId);
    else setSelectedCoachingCell('');
  };

  const setSearchBoxState = (text: string) => {
    allCoachings.current = [];
    setClientCode(text);
    setPage(0);
  };

  const renderSearch = () => (
    <SearchBox
      setSearchBoxState={setSearchBoxState}
      placeholder={I18n.t('ui.coachings.searchPlaceholder')}
      minChars={2}
    />
  );

  const onActionButtonPress = (coaching: Coaching, activeButton: ButtonOptions) => {
    setSelectedCoaching({ coaching, activeButton });
  };

  const closeModal = () => {
    setSelectedCoaching(undefined);
  };

  const renderInvoiceModal = () => {
    if (selectedCoaching?.activeButton !== 'Show Invoice') return null;

    return (
      <ModalView
        isVisible={!!selectedCoaching}
        closeModal={closeModal}
      >
        <InvoiceModal
          coaching={selectedCoaching.coaching}
        />
      </ModalView>
    );
  };

  const renderCoachingsList = () => {
    if (!scheduledCoachings) return null;
    const coachings = _.map(scheduledCoachings, (coaching, index) => (
      <CoachingRow
        key={coaching.id}
        coaching={coaching}
        selectedCoaching={selectedCoaching}
        selectedCoachingCell={selectedCoachingCell}
        isLastRow={(index === _.size(scheduledCoachings) - 1)}
        onActionButtonPress={onActionButtonPress}
        toggleActiveCell={toggleActiveCell}
        setSelectedCoachingFromChild={setSelectedCoachingFromChild}
      />
    ));
    return (<View>{coachings}</View>);
  };

  return (
    <Section
      icon={isOngoing ? Style.Icon.ClockClockwise : Style.Icon.CheckCircle}
      title={sectionTitle}
      key={sectionTitle}
      titleRightComponent={renderSearch()}
    >
      {scheduledCoachings.length > 0 && (
        <View style={styles.coachingsContainer}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ marginTop: 6 }}
            scrollEventThrottle={16}
            onScroll={(e) => {
              if (isCloseToBottom(e.nativeEvent)) {
                setPage(allCoachings.current.length);
              }
            }}
          >
            {renderCoachingsList()}
          </ScrollView>
        </View>
      )}
      <View style={{ height: (allCoachingsForPage && scheduledCoachings.length === 0) ? 48 : 30 }}>
        {!(allCoachingsForPage || errAllCoachings) && <LoadingIndicator text={I18n.t('ui.coachings.loadingCoachings')} />}
        {(allCoachingsForPage || errAllCoachings) && scheduledCoachings.length === 0 && (
          <EmptyListHint text={I18n.t(`ui.coachings.emptyListHint.${status}`)} />
        )}
      </View>
      {renderInvoiceModal()}
    </Section>
  );
};

export default CoachingsSummary;

const styles = StyleSheet.create({
  coachingsContainer: {
    maxHeight: 500,
  },
});
