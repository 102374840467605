import React from 'react';
import { SvgProps } from 'react-native-svg';

import AlignLeft from '../../assets/img/icons/regular/alignleft.svg';
import Archive from '../../assets/img/icons/regular/archive.svg';
import ArrowCircleUpRight from '../../assets/img/icons/regular/arrowcircleupright.svg';
import ArrowLineLeft from '../../assets/img/icons/regular/arrowlineleft.svg';
import ArrowSquareRight from '../../assets/img/icons/regular/arrowsquareright.svg';
import Buildings from '../../assets/img/icons/regular/buildings.svg';
import Calendar from '../../assets/img/icons/regular/calendar.svg';
import CalendarX from '../../assets/img/icons/regular/calendarx.svg';
import CaretCircleDoubleRight from '../../assets/img/icons/regular/caretcircledoubleright.svg';
import CaretDoubleLeft from '../../assets/img/icons/regular/caretdoubleleft.svg';
import CaretDoubleRight from '../../assets/img/icons/regular/caretdoubleright.svg';
import CaretLeft from '../../assets/img/icons/regular/caretleft.svg';
import CaretRight from '../../assets/img/icons/regular/caretright.svg';
import ChalkboardTeacher from '../../assets/img/icons/regular/chalkboardteacher.svg';
import ChartLineUp from '../../assets/img/icons/regular/chartlineup.svg';
import CheckCircle from '../../assets/img/icons/regular/checkcircle.svg';
import ClockClockwise from '../../assets/img/icons/regular/clockclockwise.svg';
import Cube from '../../assets/img/icons/regular/cube.svg';
import Door from '../../assets/img/icons/regular/door.svg';
import Gear from '../../assets/img/icons/regular/gear.svg';
import Globe from '../../assets/img/icons/regular/globe.svg';
import Hash from '../../assets/img/icons/regular/hash.svg';
import Heartbeat from '../../assets/img/icons/regular/heartbeat.svg';
import Hourglass from '../../assets/img/icons/regular/hourglass.svg';
import HourglassLow from '../../assets/img/icons/regular/hourglasslow.svg';
import House from '../../assets/img/icons/regular/house.svg';
import Info from '../../assets/img/icons/regular/info.svg';
import Invoice from '../../assets/img/icons/regular/invoice.svg';
import Key from '../../assets/img/icons/regular/key.svg';
import Lightbulb from '../../assets/img/icons/regular/lightbulb.svg';
import ListBullets from '../../assets/img/icons/regular/listbullets.svg';
import MagnifyingGlass from '../../assets/img/icons/regular/magnifyingglass.svg';
import MenuDot from '../../assets/img/icons/regular/menudot.svg';
import Moon from '../../assets/img/icons/regular/moon.svg';
import Note from '../../assets/img/icons/regular/note.svg';
import PaperPlaneRight from '../../assets/img/icons/regular/paperplaneright.svg';
import Path from '../../assets/img/icons/regular/path.svg';
import Pencil from '../../assets/img/icons/regular/pencil.svg';
import Phone from '../../assets/img/icons/regular/phone.svg';
import Play from '../../assets/img/icons/regular/play.svg';
import PlusCircle from '../../assets/img/icons/regular/pluscircle.svg';
import Receipt from '../../assets/img/icons/regular/receipt.svg';
import Sun from '../../assets/img/icons/regular/sun.svg';
import TagSimple from '../../assets/img/icons/regular/tagsimple.svg';
import Trash from '../../assets/img/icons/regular/trash.svg';
import Tray from '../../assets/img/icons/regular/tray.svg';
import UserCircle from '../../assets/img/icons/regular/usercircle.svg';
import Warning from '../../assets/img/icons/regular/warning.svg';
import X from '../../assets/img/icons/regular/x.svg';
import XCircle from '../../assets/img/icons/regular/xcircle.svg';

// Other SVGs
import SectionChevron from '../../assets/img/icons/regular/sectionchevron.svg';
import ArrowLeft from '../../assets/img/icons/regular/arrowleft.svg';
import ArrowRight from '../../assets/img/icons/regular/arrowright.svg';
import Plus from '../../assets/img/icons/regular/plus.svg';
import Union from '../../assets/img/icons/regular/union.svg';
import Ellipse from '../../assets/img/icons/regular/ellipse.svg';
import Sticker from '../../assets/img/icons/regular/sticker.svg';
import Copy from '../../assets/img/icons/regular/copy.svg';
import ChartDonut from '../../assets/img/icons/regular/chartdonut.svg';

const Icons = {
  AlignLeft,
  Archive,
  ArrowCircleUpRight,
  ArrowLineLeft,
  ArrowSquareRight,
  Buildings,
  Calendar,
  CalendarX,
  CaretCircleDoubleRight,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
  ChalkboardTeacher,
  ChartLineUp,
  CheckCircle,
  ClockClockwise,
  Cube,
  Door,
  Gear,
  Hash,
  Heartbeat,
  Hourglass,
  HourglassLow,
  House,
  Info,
  Invoice,
  Key,
  Lightbulb,
  ListBullets,
  MagnifyingGlass,
  MenuDot,
  Moon,
  Note,
  PaperPlaneRight,
  Path,
  Pencil,
  Phone,
  Play,
  PlusCircle,
  Receipt,
  Sun,
  TagSimple,
  Trash,
  Tray,
  UserCircle,
  Warning,
  X,
  XCircle,
  // Other SVGs
  SectionChevron,
  ArrowLeft,
  ArrowRight,
  Plus,
  Union,
  Globe,
  Ellipse,
  Sticker,
  Copy,
  ChartDonut,
};

export type IconName = keyof (typeof Icons);

interface IconByNameProps extends React.ComponentPropsWithRef<React.FC<SvgProps>> {
  name: IconName;
}

const ByName = (
  { name, ...rest }: IconByNameProps,
) => React.createElement<SvgProps>(Icons[name], rest);

export default {
  ...Icons,
  ByName,
};
