import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { observer } from 'mobx-react-lite';
import { mutate } from 'swr';
import I18n from 'i18n-js';
import {
  IndexPath, Select, SelectItem,
} from '@ui-kitten/components';

import { RootStoreContext } from '../stores/RootStore';
import Style from '../style';
import { fetchUser, updateUser } from '../api/Users';
import Button from './Button';
import TextInput from './TextInput';
import { EMAIL_RX, NAME_RX, SUCCESS } from '../util/helpers';
import ApiNotifBottom from './ApiNotifBottom';

interface EPDProps {
  closeModal: () => void;
}

const EditPersonalData = observer(({ closeModal }: EPDProps) => {
  const store = useContext(RootStoreContext);
  const myId = store.auth.userId;
  if (!myId) return null;
  const [infoChanged, setInfoChanged] = useState(false);
  const { data: myDetails } = fetchUser(myId).swr;
  const fetchUserUrl = fetchUser(myId).url;
  if (myDetails && 'httpStatus' in myDetails) store.uiState.checkError(myDetails);
  if (!myDetails) return null;

  const genders = ['m', 'f', 'o'];
  const [genderIndex, setGenderIndex] = useState<IndexPath>(
    new IndexPath(myDetails.gender ? genders.indexOf(myDetails.gender) : 0),
  );
  const [loading, setLoading] = useState(false);
  const [apiRes, setApiRes] = useState('');
  const inputs = Array.from(Array(3), () => React.createRef<TextInput>());

  const save = async () => {
    setApiRes('');
    // Make sure all inputs are valid
    // eslint-disable-next-line no-restricted-syntax
    for (const input of inputs) {
      if (!input.current?.checkAndComplain()) return;
    }
    // Save data
    const [firstName, lastName, email] = inputs.map((input) => input.current?.text || '');
    const gender = genders[genderIndex.row];

    const body = {
      ...(firstName !== '' && { firstName: firstName.trim() }),
      ...(lastName !== '' ? { lastName: lastName.trim() } : { lastName: null }),
      ...(gender !== '' && { gender }),
      ...(myDetails.email !== email.trim() && { email: email.trim() }),
    };
    setLoading(true);
    try {
      const res = await updateUser(myId, body);
      if (res.id) await mutate(fetchUserUrl, res);
      setApiRes(SUCCESS);
      setTimeout(() => { closeModal(); }, 2000);
    } catch (err) {
      if (err instanceof Error) {
        setApiRes(err.message.toString());
        setTimeout(() => { setApiRes(''); }, 4300);
      }
    } finally {
      setLoading(false);
    }
  };

  const editName = () => (
    <View style={styles.inputView}>
      <TextInput
        caption={I18n.t('ui.personalData.firstName')}
        text={myDetails.firstName}
        rx={NAME_RX}
        errMsg={I18n.t('error.pleaseEnterValidName')}
        ref={inputs[0]}
        testId="EditPersonalData.FirstnameInput"
        boxStyle={styles.boxStyle}
        onChange={() => { setInfoChanged(true); }}
      />
      <TextInput
        caption={I18n.t('ui.personalData.lastName')}
        text={myDetails.lastName}
        ref={inputs[1]}
        testId="EditPersonalData.LastnameInput"
        boxStyle={styles.boxStyle}
        optional
        onChange={() => { setInfoChanged(true); }}
      />
    </View>
  );

  const editGender = () => (
    <View style={[styles.spaceV, styles.inputView]}>
      <Text style={styles.caption} selectable={false}>{I18n.t('ui.personalData.gender.title')}</Text>
      <Select
        selectedIndex={genderIndex}
        onSelect={(index) => {
          setGenderIndex(index as IndexPath);
          setInfoChanged(true);
        }}
        value={I18n.t(`ui.personalData.gender.${genders[genderIndex.row]}`)}
        style={{ backgroundColor: 'white' }}
      >
        {genders.map((option) => (
          <SelectItem key={option} title={I18n.t(`ui.personalData.gender.${option}`)} />
        ))}
      </Select>
    </View>
  );

  const editEmail = () => (
    <View style={styles.inputView}>
      <TextInput
        caption={I18n.t('ui.login.email')}
        hint="alice@example.com"
        text={myDetails.email}
        rx={EMAIL_RX}
        errMsg={I18n.t('error.login.pleaseEnterValidEmail')}
        ref={inputs[2]}
        testId="EditPersonalData.EmailInput"
        boxStyle={styles.boxStyle}
        onChange={() => { setInfoChanged(true); }}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{I18n.t('ui.personalData.edit')}</Text>
      <View style={{ alignItems: 'flex-start' }}>
        {editName()}
        {editGender()}
        {editEmail()}
      </View>
      <View style={styles.buttonPad}>
        <Button
          btnStyleHovered={styles.btnSaveHovered}
          onPress={save}
          title={I18n.t('ui.buttons.save')}
          testId="PersonalData.EditButton"
          disable={!infoChanged}
          icon="PaperPlaneRight"
          loading={loading}
        />
      </View>
      {!!apiRes && <View style={styles.disableLayer} />}
      {!!apiRes && <ApiNotifBottom apiRes={apiRes} successText={I18n.t('other.updatedSuccessfully')} />}
    </View>
  );
});

export default EditPersonalData;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 30,
    backgroundColor: Style.Color.White,
  },
  header: {
    ...Style.Text.Heading2,
    color: Style.Color.Black,
    textAlign: 'center',
    width: '100%',
    marginBottom: 25,
    zIndex: 10,
  },
  caption: {
    ...Style.Text.Small,
    color: Style.Color.Gray400,
    marginBottom: 5,
  },
  buttonPad: {
    width: '100%',
    height: 40,
    alignSelf: 'center',
    marginBottom: 24,
    marginTop: 24,
  },

  btnSaveHovered: {
    marginLeft: 4,
    backgroundColor: Style.Color.Primary,
  },
  spaceV: {
    marginBottom: 18,
  },
  inputView: {
    width: 400,
  },
  boxStyle: {
    borderColor: Style.Color.Gray300,
    outlineColor: Style.Color.Gray300,
    color: Style.Color.Gray600,
  },
  notification: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationText: {
    ...Style.Text.Normal,
    color: Style.Color.White,
    marginLeft: 10,
  },
  disableLayer: {
    backgroundColor: Style.Color.White,
    height: '100%',
    width: '100%',
    position: 'absolute',
    opacity: 0.5,
    borderRadius: 4,
  },
});
