import Style from '../style';
import { MenuInfo } from '../types/Menu';

/*
const dashboards = {
  header: { title: 'Dashboards', color: Style.Color.Secondary },
  menuItems: [
    { title: 'Home', MenuIcon: Style.Icon.House },
  ],
};
*/

const coaching = {
  header: { title: 'Coaching', color: Style.Color.Secondary },
  menuItems: [
    { title: 'Calls', MenuIcon: Style.Icon.Phone },
    { title: 'Availabilities', MenuIcon: Style.Icon.Calendar },
    { title: 'Coachings', MenuIcon: Style.Icon.ChartLineUp },
  ],
};

const resources = {
  header: { title: 'Resources', color: Style.Color.Tertiary },
  menuItems: [
    { title: 'Projects', MenuIcon: Style.Icon.AlignLeft },
  ],
};

const settings = {
  header: { title: 'Settings', color: Style.Color.Primary },
  menuItems: [
    { title: 'Profile', MenuIcon: Style.Icon.UserCircle },
  ],
};

/* You also need to allow the screen for the role in AccessRules */
const sidebarMenus: MenuInfo[] = [
  // dashboards, // Keep it disabled until the home screen is finalized
  coaching,
  resources,
  settings,
];

export default sidebarMenus;
